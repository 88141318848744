import React from 'react'

import CourseListItem from 'components/course-list-item'

export default function ({ items }) {
    return <>
        {
            items.map(each => {
                return <CourseListItem key={each.to} item={each} />
            })
        }
    </>
}