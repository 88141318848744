import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'

import CourseList from 'components/course-list'

export default function ({ pageContext, data }) {
    const { allSitePage: { courses } } = data

    return <>
        <Helmet>
            <title>{pageContext.helmetTitle}</title>
        </Helmet>

        <CourseList items={courses} />
    </>
}

export const query = graphql`
    query {
        allSitePage(filter: {path: {regex: "/course/\\w+/"}}) {
            courses: nodes {
                context {
                    id
                    title
                    to
                    courseDuration
                }
            }
        }
    }
`